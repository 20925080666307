import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import Login from './Login';

const LoginModal = ({ ...props }) => {
    return (
        <Modal trigger={<Button style={{ marginRight: 20 }} floated="right" >Sign in</Button>}>
            <Modal.Header>Sign in</Modal.Header>
            <Modal.Content>
                <Login inline={false} {...props} />
            </Modal.Content>
        </Modal>
    )
}

export default LoginModal;
