import Cookies from 'js-cookie';

/**
 * Stores passed value in cookies
 */
export const setCookie = (name, value, expires = { expires: 30 }) => {
  Cookies.set(name, value, expires);
};

/**
 * Removes Cookie
 * @param name
 */
export const removeCookie = (name) => Cookies.remove(name);

/**
 * Gets Cookie value
 */
export const getCookie = (name) => Cookies.get(name);
