import axios from 'axios';
import { getToken, isAuthenticated, deleteToken } from '../helpers/auth';
import { backend } from '../helpers/config';

let apiUrl = backend;

const defaultValues = {};
defaultValues.baseURL = apiUrl;
defaultValues.headers = {
    'Content-Type': 'application/json',
};

const client = axios.create(defaultValues);

const request = options => {
    if (isAuthenticated()) {
        client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
    }

    const onSuccess = response => response;
    const onError = error => {
        if (error.response && error.response.status && error.response.status === 401) {
            const urlArray = error.response.config.url.split("/")
            const path = urlArray[urlArray.length - 1]

            if (path !== "login") {
                deleteToken()
                window.location.reload()
            }

        }

        if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
            if (error.response) {
                console.group(
                    `%cRequest error: ${error.response.config.url}`,
                    'font-size: 0.85rem; font-weight: bold; padding-top: 10px;',
                );
                console.error('Method:', error.response.config.method.toUpperCase());
                console.error('Status:', error.response.status);
                console.error('Data:', error.response.data);
                console.error('Headers:', error.response.headers);
                console.groupCollapsed('Trace:');
                console.trace();
                console.groupEnd();
            } else {
                console.group('Error message:');
                console.error(error.message);
                console.groupEnd();
            }
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;
