import React, { Fragment, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'semantic-ui-react';

const MyCopyToClipboard = ({ text }) => {
    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
        setCopied(true)

        setTimeout(() => setCopied(false), 3000)
    }

    return (
        <Fragment>
            {/* {copied &&
                <span style={{ display: 'inline-block', marginRight: '0.5em' }} >
                    <Header as="h4" color="green" ></Header>
                </span>
            } */}
            <CopyToClipboard
                text={text}
                onCopy={handleCopy}
            >
                <Button
                    style={{ marginLeft: 20 }}
                    primary
                    children={copied ? "Copied!" : "Copy token"}
                />
            </CopyToClipboard>
        </Fragment>
    )
}

export default MyCopyToClipboard
