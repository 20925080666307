import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { backend } from "../helpers/config";
import request from "../api/request";
import { Form, Message, Modal } from "semantic-ui-react";

const TwoFactorModal = ({ onToken, twoFactorToken, username, closeModal }) => {
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const socket = io(backend, { query: { twoFactorToken, username } });

        socket.on("auth:login:res", event => {
            socket.disconnect();

            onToken(event.token);
        });

        return () => {
            socket.disconnect();
        };
    }, [twoFactorToken, username, onToken]);

    const handleLogin = async e => {
        if (e) {
            e.preventDefault();
        }

        setLoading(true);

        try {
            const res = await request({
                url: "/auth/octaos-id-app/confirm?useSocket=false",
                method: "POST",
                data: { email: username, code, token: twoFactorToken },
            });

            setLoading(false);

            if (res?.data?.token) {
                onToken(res.data.token);
            } else {
                throw new Error("general");
            }
        } catch (error) {
            setLoading(false);

            const key = error?.data?.msgKey || "general";

            setError(key);
        }
    };

    return (
        <Modal open onClose={closeModal}>
            <Modal.Header>Notification sent</Modal.Header>

            <Modal.Content>
                A notification has been sent to your device.
                <div style={{ height: 16 }} />
                <p>If the notification did not show up, you can enter the code generated in the app here:</p>
                <Form onSubmit={handleLogin} error={error !== null}>
                    <Form.Input
                        id="code"
                        name="code"
                        type="text"
                        label="Code"
                        error={error !== null}
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        onFocus={() => setError(null)}
                    />
                    <Form.Button
                        children="Send"
                        type="submit"
                        loading={loading}
                        disabled={code.length <= 0 || loading}
                    />
                    <Message style={{ marginBottom: 16, zIndex: 999 }} error content={error} />
                </Form>
            </Modal.Content>
        </Modal>
    );
};

export default TwoFactorModal;
