import React, { Fragment, useState } from 'react';
import { Header } from 'semantic-ui-react'

import useApi from '../api/useApi'

const _userGroups = {
    carpenter: "Carpenter",
    consumer: "Consumer",
    manufacturer_admin: "Manufacturer Admin",
    manufacturer_sales_admin: "Manufacturer Sales Admin",
    manufacturer_sales_user: "Manufacturer Sales User",
    merchant_admin: "Merchant Admin",
    merchant_user: "Merchant User",
    multi_merchant_admin: "Multi Merchant Admin",
    no_access: "No access",
    partner_system_admin: "Partner System Admin",
    system_admin: "System Admin",
}

const MyHeader = () => {
    const [apiOptions] = useState({ url: "/user", method: "GET" });
    const [isLoading, error, user] = useApi(apiOptions);

    return (
        <Fragment>
            {!isLoading && !error && user && user.name &&
                <Header as="h2" >
                    {user.name}
                    <Header.Subheader>{_userGroups[user.groups[0].name]}</Header.Subheader>
                </Header>
            }
        </Fragment>
    )
}

export default MyHeader