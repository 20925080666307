import React, { Fragment, useState } from "react";
import { Grid, Segment } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";

import { setToken as setTokenCookie, isAuthenticated, getToken, deleteToken } from "./helpers/auth";

import DocsIframe from "./views/DocsIframe";
import Login from "./views/Login";
import Logout from "./views/Logout";
import Header from "./components/Header";
import LoginModal from "./views/LoginModal";
import CopyToClipboard from "./components/CopyToClipboard";

const AppMedia = createMedia({
    breakpoints: {
        tablet: 0,
        computer: 800,
    },
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

const App = () => {
    const [isAuth, setIsAuth] = useState(isAuthenticated());
    const [token, setToken] = useState(isAuth ? getToken() : null);
    const [error, setError] = useState(null);

    const handleAuth = token => {
        setToken(token);
        setTokenCookie(token);
        setIsAuth(token !== null);
    };

    const handleLogout = () => {
        deleteToken();
        setToken(null);
        setIsAuth(false);
    };

    return (
        <div className="App">
            <style>{mediaStyles}</style>

            {isAuth ? (
                <Segment
                    style={{
                        margin: 0,
                        height: 78,
                    }}
                >
                    <Grid>
                        <Grid.Row columns="3">
                            <Grid.Column>
                                <CopyToClipboard text={token} />
                            </Grid.Column>
                            <Grid.Column textAlign="center">
                                <Header />
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Logout onLogout={handleLogout} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            ) : (
                <Fragment>
                    <MediaContextProvider>
                        <Segment.Group
                            style={{
                                margin: 0,
                                height: 78,
                            }}
                        >
                            <Segment as={Media} at="tablet">
                                <LoginModal error={error} setError={setError} onAuth={handleAuth} />
                            </Segment>
                            <Segment as={Media} greaterThanOrEqual="computer">
                                <Login error={error} setError={setError} onAuth={handleAuth} />
                            </Segment>
                        </Segment.Group>
                    </MediaContextProvider>
                </Fragment>
            )}

            <DocsIframe token={token} />
        </div>
    );
};

export default App;
