import React, { useState, useEffect } from "react";
import { Form, Message } from "semantic-ui-react";

import request from "../api/request";
import TwoFactorModal from "./TwoFactorModal";

const Login = ({ error, setError, onAuth, inline = true }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [twoFactorModalOpen, setTwoFactorModalOpen] = useState(false);
    const [twoFactorModalUsername, setTwoFactorModalUsername] = useState();
    const [twoFactorModalToken, setTwoFactorModalToken] = useState();


    useEffect(() => {
        setError(null);
    }, [username, password, loading, setError]);

    const handleToken = token => {
        setLoading(false);
        onAuth(token);
    }

    const handleTwoFactorModalClose = () => {
        setTwoFactorModalOpen(false);
        setLoading(false);
    }


    const login = async () => {
        setLoading(true);
        setTwoFactorModalUsername(username);

        try {
            const res = await request({
                url: "/auth/login",
                method: "POST",
                data: { username, password },
            });

            if (res.data.type === "two-factor-app") {
                setTwoFactorModalToken(res.data.token);
                setTwoFactorModalOpen(true);
            } else {
                handleToken(res.data.token);
            }
        } catch (error) {
            setLoading(false);
            setError(error.status ? error.status : 500);
        }
    };

    const getErrorMessage = () => {
        switch (error) {
            case 401:
                return "Wrong username or password.";
            default:
                return `Something went wrong. Code: ${error}`;
        }
    };

    return (
        <>
            <Form style={{ maxWidth: 1000, margin: "0 auto" }} error={error !== null}>
                <Form.Group inline={inline} widths="equal">
                    <Form.Input
                        type="text"
                        label="Username"
                        error={error !== null}
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                    <Form.Input
                        type="password"
                        label="Password"
                        error={error !== null}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Form.Button children="Sign in" onClick={login} loading={loading} disabled={loading} />
                </Form.Group>
                <Message style={{ marginBottom: 16, zIndex: 999 }} error content={getErrorMessage()} />
            </Form>

            {twoFactorModalOpen && (
                <TwoFactorModal
                    onToken={handleToken}
                    twoFactorToken={twoFactorModalToken}
                    username={twoFactorModalUsername}
                    closeModal={handleTwoFactorModalClose}
                />
            )}
        </>
    );
};

export default Login;
