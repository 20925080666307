import { useState, useEffect } from "react";

import request from "./request";

const useCall = options => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const handleCall = () => {
        setError(null);
        setIsLoading(true);

        request(options)
            .then(res => {
                setData(res.data);
                setIsLoading(false);
            })
            .catch(e => {
                setError(e);
                setIsLoading(false);
            });
    };

    useEffect(handleCall, [options]);

    return [isLoading, error, data];
};

export default useCall;
