import React, { Fragment, useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react'

import { backend } from '../helpers/config'

const DocsIframe = ({ token }) => {
    const [iframeLoading, setIframeLoading] = useState(false)

    const iframeElm = React.createRef()

    useEffect(() => {
        iframeElm.current.addEventListener('load', () => setIframeLoading(false))
    }, [iframeElm])

    useEffect(() => {
        setIframeLoading(true)
    }, [token])

    return (
        <Fragment>
            <Loader active={iframeLoading} />
            <div
                style={{
                    position: 'absolute',
                    top: 78,
                    bottom: 16,
                    left: 0,
                    right: 0,
                }}
            >
                <iframe
                    ref={iframeElm}
                    title="api"
                    src={token ? `${backend}/swagger-ui?token=${token}` : `${backend}/swagger-ui/`}
                    style={{ border: 0, width: '100%', height: '100%' }}
                />
            </div>
        </Fragment>
    )
}

export default DocsIframe
