import React from 'react';
import { Button } from 'semantic-ui-react';

import request from '../api/request'

const Logout = ({ onLogout }) => {

    const handleLogout = () => {
        request({ url: "/auth/logout", method: "POST" })

        onLogout()
    }

    return (
        <Button
            style={{ marginRight: 20 }}
            children="Sign out"
            floated="right"
            onClick={handleLogout}
        />
    )
}

export default Logout;
