import { getCookie, setCookie, removeCookie } from './cookies';

const COOKIE_NAME = 'token';

/**
 * Stores user token in cookies
 * @param {String} token - User JWT token
 */
export const setToken = (token) => setCookie(COOKIE_NAME, token);

/**
 * Checks if user is authenticated
 * @returns {boolean}
 */
export const isAuthenticated = () => !!getCookie(COOKIE_NAME);

/**
 * Removes JWT token from cookies
 */
export const deleteToken = () => removeCookie(COOKIE_NAME);

/**
 * Gets user JWT token
 * @returns {String} - JWT token
 */
export const getToken = () => getCookie(COOKIE_NAME);
